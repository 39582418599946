@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  scrollbar-width: none;
}

.customeTrinsition {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

/* cutome mobile Input */
.PhoneInput {
  margin-top: 20px;
  height: 55px;
}

.PhoneInput {
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  overflow: hidden;
}

.PhoneInput .PhoneInputCountry {
  padding-left: 10px;
  padding-right: 10px;
}

.PhoneInput .PhoneInputInput {
  border-left: 1px solid #cfcfcf;
  outline: none;
  height: 100%;
  padding-left: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.swal-button-container {
  width: 100% !important;
  justify-content: center !important;
  display: flex !important;
}
.swal-title {
  font-weight: 700 !important;
  color: #111 !important;
  font-size: 32px;
}
.swal-button {
  background-color: #111 !important;
  color: white;
  border-radius: 9999px;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s !important;
}

.swal-button:hover {
  opacity: 0.9 !important;
  transform: scale(1.02) !important;
}

.swal-footer {
  display: flex !important;
  justify-content: center;
  gap: 10px;
}

.swal-footer .swal-button-container {
  width: unset !important;
}
.swal-footer .swal-button-container .swal-button--close {
  background-color: #e25c5c !important;
}

/* filter toggle */
/* Toggle Switch Styling */
.toggle-checkbox {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle-checkbox:checked {
  background-color: #111111;
}

.toggle-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

.toggle-checkbox:checked::before {
  transform: translateY(-50%) translateX(20px);
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0; /* Makes the thumb invisible */
  height: 0;
}

input[type="range"]::-moz-range-thumb {
  width: 0; /* For Firefox */
  height: 0;
  appearance: none;
}
.custom-swal-modal {
  width: 410px !important;
}
